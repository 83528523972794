.game {
 padding: 2rem;
 color:#fff;
 background-color: #0c0c0c;
 
 font-family: 'Stick No Bills', sans-serif;
 font-size: 2rem;
}

/* Buttons && Links && a */
.game a, .game button {
    transition: .5s ease;
    display: block;
    color:#fff;
    text-decoration: none;
    padding: 1rem;
    margin: 1rem 0rem;
    background-color: rgb(0, 85, 85);
    border: transparent 1px solid;
    outline: none;
}

.game a:hover, .game button:hover {
    background-color: teal;
    border: wheat 1px solid;
}

.game a:focus, .game button:focus {
    background-color: teal;
    border: wheat 1px solid;
    box-shadow: none;
}

/* .danger => Buttons && Links && a */
.game .danger {
    background-color: rgb(163, 17, 46);
    padding: .5rem 1rem;
}

.game .danger:hover {
    background-color: crimson;
}

.game .danger:focus {
    background-color: crimson;
}

.game .outline {
    border-color: currentColor;
    background-color: unset;
}
