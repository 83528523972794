@keyframes cardMoveImgDef {
  from {
    opacity: 1;
    background-position: 50%;
  }
  to {
    opacity: 0;
    background-position: 0%;
  }
}

.cardMoveImg {
  animation-duration: 4s;
  animation-name: cardMoveImgDef !important;
  opacity: 0;
}
