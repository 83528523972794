@keyframes animDefOpacityOff {
  from { 
    opacity: 1;
  } to {
    opacity: 0;
  }
}

.game .hide {
  animation: 2s animDefOpacityOff;
  opacity: 0;
}

@keyframes animDefOpacityOff2 {
  0% { 
    opacity: 1;
  } 75% {
    opacity: 0;
  } 100% {
    opacity: 0;
  }
}

.game .hide2 {
  animation: 5s animDefOpacityOff2;
  opacity: 0;
}

@keyframes animDefOpacityOn {
  from { 
    opacity: 0;
  } to {
    opacity: 1;
  }
}
.game .show {
  animation: .5s animDefOpacityOn;
  opacity: 1;
}

.game .discovered {  
  animation: 1s animDefOpacityOff;
  opacity: 0;
  cursor: default;
}