.game .row {
 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
}

.game .tile {
    width: 3rem;
    height: 3rem;
    
    margin: 2px;
}

.game .border-crimson {
    border: crimson solid 1px;
}

.game .border-darkt1 {
    border: #2d2d2d00 solid 1px; 
    width: 1rem;
    padding: 0px 2px;
    margin: 0px 2px;
}

