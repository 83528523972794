/* Intro Start */

@keyframes dilateAnim {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.dilateAnim {
  animation: dilateAnim 7s;
}

/*@keyframes initialGradientStart {
  0% {
    box-shadow: 2px 2px 4px rgb(0, 0, 0);
  }
  100% {
    box-shadow: 2px 2px 0px rgb(0, 0, 0);
  }
}

.initialGradientStart {
  animation: 7s;
  animation-name: initialGradientStart;
}

.initialGradient {      
  background-image: linear-gradient(to right, #ced0c5 50%, #ced0c5 0%);    
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}*/

.text-color-t1 {
  color: #ced0c5;
}

.text-color-t2 {
  color: #1e1e1e;
}

@keyframes borderStart {
  0% {
    box-shadow: 2px 2px #c3cadc;
  }
  11% {
    box-shadow: -2px 2px #c3cadc;
  }
  22% {
    box-shadow: -2px -2px #c3cadc;
  }
  33% {
    box-shadow: 2px -2px #c3cadc;
  }
  44% {
    box-shadow: 2px 2px #c3cadc;
  }
  66% {
    box-shadow: -2px 2px #c3cadc;
  }
  77% {
    box-shadow: -2px -2px #c3cadc;
  }
  88% {
    box-shadow: 2px -2px #c3cadc;
  }
  100% {
    box-shadow: 2px 2px #c3cadc;
  }
}

.borderStart {
  animation: borderStart 0.2s linear 7s;
}

#viewBestProjects {
  width: 8rem;
  height: 8rem;
  font-size: 1.5rem;
  border-radius: 50%;
  border: solid 2px #b1c37722;
  background-color: #b1c37711;
  color: #b1c377aa;
  position: absolute;
  bottom: 1.5rem;
  right: calc(0vw + 1rem);
  box-shadow: 2px 2px #c3cadc;
  text-decoration: none;
  opacity: 1;
  outline: none;
  transition: ease-in-out 0.2s;
}

#viewBestProjects:hover {
  background-color: #c1df6144;
  animation: borderStart 0.2s linear;
}

#viewBestProjects:active {
  transform: scale(0.9);
}

@keyframes opacityAnimStart {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.opacityAnimStart {
  animation: opacityAnimStart 6.5s ease;
}

.text-vertical {
  word-wrap: break-word;
  text-align: center;
}

#intro-h1 {
  width: 2.5rem;
  font-size: 3.5rem;
}

#intro-h2 {
  width: 3rem;
  font-size: 3rem;
}

#intro-h3 {
  text-align: center;
  font-size: 0.9rem;
  border-bottom: #b1c37744 solid 2px;
}

#ajustM {
  font-size: 2.5rem;
}

@media screen and (max-width: 600px) {
  #viewBestProjects {
    width: 6rem;
    height: 6rem;
    font-size: 1.2rem;
  }
}

/* Intro End */

/* Projects Start */

#projects-h1 {
  width: 2.5rem;
  font-size: 3rem;
}

#projects-h3 {
  text-align: center;
  font-size: 0.9rem;
  border-bottom: #fff solid 2px;
}

#projects-button-home {
  right: 0;
  color: #fff;
}

#projects-button-myt {
  right: 0;
  color: #fff;
  bottom: -100%;
}

@keyframes borderStart-t2 {
  0% {
    box-shadow: 2px 2px currentColor;
  }
  11% {
    box-shadow: -2px 2px currentColor;
  }
  22% {
    box-shadow: -2px -2px currentColor;
  }
  33% {
    box-shadow: 2px -2px currentColor;
  }
  44% {
    box-shadow: 2px 2px currentColor;
  }
  66% {
    box-shadow: -2px 2px currentColor;
  }
  77% {
    box-shadow: -2px -2px currentColor;
  }
  88% {
    box-shadow: 2px -2px currentColor;
  }
  100% {
    box-shadow: 2px 2px currentColor;
  }
}

.button-t2 {
  width: 4rem;
  height: 4rem;
  font-size: 1rem;
  border-radius: 50%;
  border: solid 2px #032e4e22;
  background-color: #0e436b;
  color: #032e4e;
  box-shadow: 2px 2px currentColor;
  text-decoration: none;
  opacity: 1;
  transition: ease-in-out 0.2s;
  word-wrap: break-word;
}

.button-t2:hover {
  background-color: #032e4eaa;
  color: currentColor;
  animation: borderStart-t2 0.2s linear;
}

.button-t2:active {
  transform: scale(0.9);
}

/* Projects End */

/* End Start */
#end-button-back {
  right: 0;
  bottom: 0;
  color: #fff;
}

#end-tech {
  margin-top: 10vh;
}

/* End End */

/* _______________________________________________________  old */

.cardExpand {
  transition: 0.5s;
}

.cardExpand:hover {
  transform: scale(1.1);
  box-shadow: 0rem 0rem 1rem #0c0c0c;
}

/* Scroll y */
/* width */
::-webkit-scrollbar {
  background: #222;
  width: 0.6rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 1rem;
  height: 1rem;
  min-height: 1rem;
  width: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Landing */

@media screen and (max-height: 640px) {
  /* Intro */

  #intro-h1 {
    width: 2rem;
    font-size: 2.5rem;
  }

  #intro-h2 {
    width: 2rem;
    font-size: 2rem;
  }

  #ajustM {
    font-size: 1.5rem;
  }

  /* Projects */

  #projects-h1 {
    width: 1.5rem;
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 1000px) {
  #projects-div-projects {
    flex-direction: column;
  }

  #projects-frame {
    height: 30vh !important;
    width: 60vw !important;
    margin: 0.5rem 0;
  }

  #end-tech {
    margin-top: 0vh;
    padding-top: 5% !important;
  }
}

.text-u-none {
  text-decoration: none !important;
}

@media screen and (max-width: 700px) {
  /* Projects */
  #projects-h1 {
    width: 1.5rem;
    font-size: 2.2rem;
  }

  #projects-h3 {
    top: 50% !important;
    font-size: 1rem;
  }

  #projects-button-home {
    left: 0;
    color: #fff;
  }

  #projects-button-myt {
    left: 0;
    color: #fff;
  }
}
